"use client";

import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger
} from "@/components/shared/ui/accordion";
import { ShopCheckBoxFilter, ShopInputFilter, ShopRangeFilter } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { memo } from "react";
import FilterOption from "./filter-option";

interface FilterSectionProps {
    title: string;
    filterOptions: Array<ShopCheckBoxFilter | ShopInputFilter | ShopRangeFilter>;
    multiSelect?: boolean;
}

const FilterSection = memo<FilterSectionProps>(({ title, filterOptions, multiSelect }) => {
    const filters = ShopStore.use.filters();

    let activeCount = 0;
    for (const filter of filterOptions) {
        const currentValue = filters[filter.filterID];

        if (multiSelect && currentValue) {
            activeCount = (currentValue as string[]).length;
        } else if (currentValue) {
            activeCount++;
        }
    }

    return (
        <AccordionItem value={title} className="border-b-2 border-b-cart">
            <AccordionTrigger className="p-1 text-3xl font-medium md:text-lg">
                {title}{" "}
                {activeCount > 0 && (
                    <span className="sticky top-0 text-sm text-muted-foreground">
                        ({activeCount} active)
                    </span>
                )}
            </AccordionTrigger>
            <AccordionContent className="pl-2">
                <div className="flex flex-col gap-1">
                    {filterOptions.map((item) => (
                        <FilterOption
                            item={item}
                            key={`${item.filterID}-${item.value}`}
                            multiSelect={multiSelect}
                        />
                    ))}
                </div>
            </AccordionContent>
        </AccordionItem>
    );
});

FilterSection.displayName = "FilterSection";

export default FilterSection;
