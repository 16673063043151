import { ShopProductPageData } from "@/lib/shop/shop-types";

interface BlendProps {
    type: ShopProductPageData["blend"];
}

const Blend = ({ type }: BlendProps) => {
    const getBlendClass = (blendType: ShopProductPageData["blend"]) => {
        const baseClasses = "inline-block text-sm px-3 rounded-full w-fit h-fit whitespace-nowrap";

        const blendStyles: Record<ShopProductPageData["blend"], string> = {
            "Indica Hybrid": "bg-blend-indica-hybrid",
            Hybrid: "bg-blend-hybrid",
            "1:1 CBD": "bg-blend-onetoone",
            "Sativa Hybrid": "bg-blend-sativa-hybrid",
            "High CBD": "bg-blend-highcbd",
            Sativa: "bg-blend-sativa",
            Indica: "bg-blend-indica",
            unknown: "bg-blend-unknown"
        };

        return `${baseClasses} ${blendStyles[blendType]}`;
    };

    return <span className={getBlendClass(type)}>{type}</span>;
};

export default Blend;
