import { Skeleton } from "@/components/shared/ui/skeleton";

export function ProductRowSkeleton() {
    return (
        <div className="relative w-full border bg-white p-4">
            <div className="flex flex-col gap-4 md:grid md:grid-cols-5 md:items-center md:gap-0">
                {/* Supplier name, product type, and product name */}
                <div className="col-span-2 flex flex-col gap-2">
                    <div className="flex flex-row flex-wrap items-center gap-2">
                        <Skeleton className="h-6 w-20" /> {/* Badge skeleton */}
                        <Skeleton className="h-7 w-32" /> {/* Supplier name */}
                        <Skeleton className="h-5 w-24" /> {/* Product type */}
                    </div>
                    <Skeleton className="h-7 w-64" /> {/* Product name */}
                </div>

                {/* Blend and Potency */}
                <div className="flex flex-row items-center gap-4 md:flex-col md:gap-2">
                    <Skeleton className="h-6 w-24" /> {/* Blend */}
                    <Skeleton className="h-5 w-32" /> {/* Potency */}
                </div>

                {/* Inventory and Price */}
                <div className="flex flex-row items-center justify-between gap-2 md:flex-col md:items-start">
                    <Skeleton className="h-6 w-28" /> {/* Inventory badge */}
                    <div className="flex flex-row items-center gap-1">
                        <Skeleton className="h-6 w-24" /> {/* Price */}
                        <Skeleton className="h-5 w-12" /> {/* Count */}
                    </div>
                </div>

                {/* Add to cart section */}
                <div className="mt-4 max-w-[200px] md:mt-0 md:max-w-[600px] md:items-end md:pr-5">
                    <Skeleton className="h-10 w-full" /> {/* Add to cart button */}
                </div>
            </div>
        </div>
    );
}
