import { Button } from "@/components/shared/ui/button";
import { Card, CardContent } from "@/components/shared/ui/card";
import { Input } from "@/components/shared/ui/input";
import { QUERY_STRING_FILTER_ID } from "@/lib/shop/shop-constants";
import { ShopSearchResult } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import debounce from "lodash/debounce";
import { Loader2, Search, X } from "lucide-react";
import { useRouter } from "next/navigation";
import { KeyboardEvent, useEffect, useState } from "react";

const ProductSearch = ({ state }: { state: string }) => {
    const setFilter = ShopStore.use.setFilter();
    const getFilter = ShopStore.use.getFilter();
    const filters = ShopStore.use.filters();

    const [searchTerm, setSearchTerm] = useState(
        (getFilter(QUERY_STRING_FILTER_ID) as string) || ""
    );
    const [searchResults, setSearchResults] = useState<ShopSearchResult[]>([]);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const router = useRouter();

    useEffect(() => {
        const newSearchTerm = getFilter(QUERY_STRING_FILTER_ID) as string;
        setSearchTerm(newSearchTerm ?? "");
    }, [getFilter, filters]);

    const updateFilters = (query: string) => {
        setFilter(QUERY_STRING_FILTER_ID, query, router);
        setShowSearchResults(false);
    };

    const fetchResults = async (query: string) => {
        if (!query) {
            setSearchResults([]);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(
                `/api/products?q=${encodeURIComponent(query)}&state=${state}`
            );
            if (!response.ok) throw new Error("Search failed");
            const data = await response.json();
            setSearchResults(data);
            setSelectedIndex(-1); // Reset selection when new results arrive
        } catch (error) {
            console.error("Search error:", error);
            setSearchResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedFetch = debounce(fetchResults, 300);

    const handleSearch = (currentValue: string) => {
        setShowSearchResults(currentValue.length > 0);
        setSearchTerm(currentValue);
        if (currentValue) {
            debouncedFetch(currentValue);
        } else {
            setSearchResults([]);
            setIsLoading(false);
        }
    };

    const clearSearch = () => {
        setSearchTerm("");
        setSearchResults([]);
        updateFilters("");
        setIsLoading(false);
        setSelectedIndex(-1);
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (!showSearchResults) {
            if (e.key === "Enter") {
                updateFilters(searchTerm);
            }
            return;
        }

        switch (e.key) {
            case "ArrowDown":
                e.preventDefault();
                setSelectedIndex((prev) => (prev < searchResults.length - 1 ? prev + 1 : prev));
                break;
            case "ArrowUp":
                e.preventDefault();
                setSelectedIndex((prev) => (prev > -1 ? prev - 1 : prev));
                break;
            case "Enter":
                e.preventDefault();
                if (selectedIndex >= 0 && selectedIndex < searchResults.length) {
                    const selectedResult = searchResults[selectedIndex];
                    if (selectedResult) {
                        window.location.href = selectedResult.URL;
                        setShowSearchResults(false);
                    }
                } else {
                    updateFilters(searchTerm);
                }
                break;
            case "Escape":
                setShowSearchResults(false);
                break;
        }
    };

    useEffect(() => {
        if (!showSearchResults) {
            setSelectedIndex(-1);
        }
    }, [showSearchResults]);

    useEffect(() => {
        if (selectedIndex >= 0) {
            const selectedElement = document.getElementById(`search-result-${selectedIndex}`);
            if (selectedElement) {
                selectedElement.scrollIntoView({
                    block: "nearest",
                    behavior: "smooth"
                });
            }
        }
    }, [selectedIndex]);

    return (
        <div className="relative m-auto w-full max-w-xl px-2">
            <div className="flex gap-2 ">
                <div className="flex flex-grow items-center overflow-hidden rounded-lg border-2 border-gray-300 bg-white pl-2">
                    {searchTerm ? (
                        <Button
                            type="button"
                            variant="ghost"
                            size="icon"
                            onClick={clearSearch}
                            className="h-4 w-4"
                        >
                            <X className="h-4 w-4 text-muted-foreground" />
                        </Button>
                    ) : (
                        <Search className="h-4 w-4 text-muted-foreground" />
                    )}
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => handleSearch(e.target.value)}
                        onFocus={() =>
                            setShowSearchResults(Boolean(searchTerm && searchTerm.length > 0))
                        }
                        onBlur={() => {
                            // Delay hiding results to allow for click handling
                            setTimeout(() => setShowSearchResults(false), 200);
                        }}
                        onKeyDown={handleKeyPress}
                        placeholder="Search products..."
                        className="w-full border-none outline-none focus:ring-transparent focus-visible:ring-transparent"
                    />
                </div>
                <div className="flex gap-2">
                    <Button
                        type="button"
                        onClick={() => updateFilters(searchTerm)}
                        className="mb-auto mt-auto"
                    >
                        <Search className="h-4 w-4" /> Search
                    </Button>
                </div>
            </div>

            {showSearchResults && (
                <Card className="absolute left-0 right-0 top-full z-50 mt-2 shadow-lg">
                    <CardContent className="p-2">
                        {isLoading && searchResults.length === 0 ? (
                            <div className="flex items-center justify-center p-4 text-muted-foreground">
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Searching...
                            </div>
                        ) : searchResults.length === 0 ? (
                            <div className="p-4 text-center text-muted-foreground">
                                No results found.
                            </div>
                        ) : (
                            <div className="max-h-72 w-full overflow-y-auto">
                                <div className="w-full whitespace-nowrap text-center text-sm text-gray-500">
                                    Hit <span className="underline">Enter</span> or click{" "}
                                    <span className="underline">Search</span> to filter the current
                                    page
                                </div>
                                {searchResults.map((result, index) => (
                                    <a
                                        href={result.URL}
                                        target="_blank"
                                        rel="noreferrer"
                                        key={result.productID}
                                        id={`search-result-${index}`}
                                        className={`block w-full rounded-md px-4 py-2 text-left transition-colors ${
                                            index === selectedIndex
                                                ? "bg-accent text-accent-foreground"
                                                : "hover:bg-accent"
                                        }`}
                                    >
                                        <span className="text-muted-foreground">
                                            ({result.productID})
                                        </span>{" "}
                                        {result.name}
                                    </a>
                                ))}
                            </div>
                        )}
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default ProductSearch;
