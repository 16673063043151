"use client";

import { Button } from "@/components/shared/ui/button";
import {
    BLENDS_FILTER_ID,
    CASE_QUANTITY_FILTER_ID,
    CATEGORY_FILTER_ID,
    DEFAULT_LAYOUT,
    DEFAULT_SORT,
    INCLUDE_OUT_OF_STOCK_FILTER_ID,
    INCLUDE_SAMPLES_FILTER_ID,
    IS_NEW_FILTER_ID,
    IS_ON_SALE_FILTER_ID,
    LAYOUT_PARAMETER_NAME,
    QUERY_STRING_FILTER_ID,
    SHOW_BELOW_ONLY_FILTER_ID,
    SHOW_MY_PRODUCTS_ONLY_FILTER_ID,
    SORT_PARAMETER_NAME,
    SORT_REVERSE_PARAMETER_NAME,
    SUPPLIER_FILTER_ID,
    TARGET_DOI_FILTER_ID,
    UNIT_PRICE_FILTER_ID
} from "@/lib/shop/shop-constants";
import {
    ShopFilterSectionContent,
    ShopFilters,
    ShopLayout,
    ShopSort,
    ShopSortOption,
    URLFilterConfig
} from "@/lib/shop/shop-types";
import { ShopStore, ShopStoreState } from "@/state-management/stores/shop-store";
import { Filter } from "lucide-react";
import { ReadonlyURLSearchParams, useRouter, useSearchParams } from "next/navigation";
import { FC, useEffect, useMemo, useState } from "react";
import FilterSidebar from "../shop-filters/filter-sidebar";
import InfiniteScrollFetch from "./infinite-scroll-fetch";
import LayoutSelection from "./layout-selection";
import ProductSearch from "./product-search";
import SortSelector from "./sort-selector";

interface ShopClientWrapperProps {
    filterSidebarContents: ShopFilterSectionContent[];
    state: string;
    defaultSort: ShopSort;
    sortOptions: ShopSortOption[];
    customerID: number | null;
}

export function getShopStateFromURL(
    searchParams: ReadonlyURLSearchParams,
    sortOptions: ShopSortOption[],
    config: URLFilterConfig = {}
): Pick<ShopStoreState, "filters" | "layout" | "sort"> {
    // filters
    const {
        arrayKeys = [],
        booleanKeys = [],
        rangeKeys = [],
        numberKeys = [],
        textKeys = [],
        excludeKeys = [],
        textConfig = {
            maxLength: 100,
            trim: true,
            lowercase: true
        }
    } = config;

    const filters: ShopFilters = {};

    const isArrayKey = (key: string) => arrayKeys.includes(key);
    const isBooleanKey = (key: string) => booleanKeys.includes(key);
    const isNumberKey = (key: string) => numberKeys.includes(key);
    const isRangeKey = (key: string) => rangeKeys.includes(key);
    const isTextKey = (key: string) => textKeys.includes(key);
    const isExcludedKey = (key: string) => excludeKeys.includes(key);

    searchParams.forEach((value, key) => {
        if (isExcludedKey(key)) return;

        if (!value) {
            filters[key] = null;
            return;
        }

        if (isTextKey(key)) {
            filters[key] = processTextValue(value as string, textConfig);
            return;
        }

        if (isArrayKey(key)) {
            filters[key] = (value as string).split(",").filter(Boolean);
            return;
        }

        if (isBooleanKey(key)) {
            filters[key] = String(value);
            return;
        }

        if (isNumberKey(key)) {
            const parsedNumber = Number(value);
            if (!isNaN(parsedNumber)) {
                filters[key] = parsedNumber.toString();
                return;
            }
        }

        if (isRangeKey(key)) {
            filters[key] = String(value);
            return;
        }

        if (value.includes(",")) {
            filters[key] = (value as string).split(",").filter(Boolean);
            return;
        }

        filters[key] = value;
    });

    // layout
    const layoutParam = searchParams.get(LAYOUT_PARAMETER_NAME);

    const layout: ShopLayout = layoutParam ? (layoutParam as ShopLayout) : DEFAULT_LAYOUT;

    // sort
    const sortKeyParam = searchParams.get(SORT_PARAMETER_NAME);
    const reverseParam = searchParams.get(SORT_REVERSE_PARAMETER_NAME);

    const sortKey = sortKeyParam ? (sortKeyParam as ShopSort["sortKey"]) : DEFAULT_SORT.sortKey;

    const type = sortOptions.find((option) => option.sortKey === sortKey)?.type || "string";

    const sort: ShopSort = {
        sortKey: sortKey,
        type: type,
        reverse: reverseParam === "true"
    };

    return { filters, layout, sort };
}

function processTextValue(value: string, config: URLFilterConfig["textConfig"] = {}): string {
    const { maxLength = 100, trim = true, lowercase = true } = config;

    let processed = value;

    if (trim) {
        processed = processed.trim();
    }

    if (lowercase) {
        processed = processed.toLowerCase();
    }

    if (maxLength && processed.length > maxLength) {
        processed = processed.slice(0, maxLength);
    }

    return processed;
}

const ShopClientWrapper: FC<ShopClientWrapperProps> = ({
    filterSidebarContents,
    state,
    sortOptions,
    customerID
}) => {
    const setFilters = ShopStore.use.setFilters();
    const setLayout = ShopStore.use.setLayout();
    const setSort = ShopStore.use.setSort();
    const updateURLFromState = ShopStore.use.updateURLFromState();
    const shopFilters = ShopStore.use.filters();
    const shopLayout = ShopStore.use.layout();
    const shopSort = ShopStore.use.sort();

    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const searchParams = useSearchParams();
    const router = useRouter();

    useEffect(() => {
        const { filters, layout, sort } = getShopStateFromURL(searchParams, sortOptions, {
            arrayKeys: [CATEGORY_FILTER_ID, SUPPLIER_FILTER_ID, BLENDS_FILTER_ID],
            booleanKeys: [
                INCLUDE_OUT_OF_STOCK_FILTER_ID,
                INCLUDE_SAMPLES_FILTER_ID,
                SHOW_BELOW_ONLY_FILTER_ID,
                SHOW_MY_PRODUCTS_ONLY_FILTER_ID,
                IS_NEW_FILTER_ID,
                IS_ON_SALE_FILTER_ID
            ],
            rangeKeys: [UNIT_PRICE_FILTER_ID, CASE_QUANTITY_FILTER_ID],
            numberKeys: [TARGET_DOI_FILTER_ID],
            textKeys: [QUERY_STRING_FILTER_ID],
            excludeKeys: [SORT_PARAMETER_NAME, SORT_REVERSE_PARAMETER_NAME, LAYOUT_PARAMETER_NAME],
            textConfig: {
                maxLength: 100,
                trim: true,
                lowercase: true
            }
        });
        setFilters(filters);
        setLayout(layout);
        setSort(sort);
        updateURLFromState(router);
    }, [setFilters, setLayout, setSort, searchParams, sortOptions, updateURLFromState, router]);

    const gridKey = JSON.stringify({
        shopFilters,
        shopSort,
        shopLayout,
        customerID
    });

    const handleFilterOpenChange = (isOpen: boolean) => {
        setFilterIsOpen(isOpen);
    };

    const filterCount = useMemo(() => {
        let count = 0;
        for (const key in shopFilters) {
            if (shopFilters[key] !== null) {
                count++;
            }
        }
        return count;
    }, [shopFilters]);

    return (
        <div className="mb-16 flex h-full w-full flex-row ">
            <FilterSidebar
                filterSidebarContents={filterSidebarContents}
                className="fixed left-0 top-20 h-full overflow-y-auto border-r md:w-64"
                isOpenExternal={filterIsOpen}
                onOpenChange={handleFilterOpenChange}
            />

            <div className="flex-1 md:ml-64">
                <div className="pt-2">
                    <ProductSearch state={state} />
                    <div className="flex w-full flex-row justify-between px-6 py-4">
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => setFilterIsOpen(!filterIsOpen)}
                            className="mr-4 flex flex-wrap gap-y-0.5 md:hidden"
                        >
                            <div className="flex flex-row items-baseline justify-between gap-1 whitespace-nowrap text-xl">
                                <Filter className="h-6 w-6" /> Filters
                            </div>
                            {filterCount > 0 ? (
                                <span className="text-gray-400">({filterCount} active)</span>
                            ) : null}
                        </Button>
                        <SortSelector sortOptions={sortOptions} />
                        <LayoutSelection />
                    </div>
                </div>
                <InfiniteScrollFetch
                    key={gridKey}
                    initialData={undefined}
                    state={state}
                    customerID={customerID}
                />
            </div>
        </div>
    );
};

export default ShopClientWrapper;
