import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/shared/ui/tooltip";
import { forwardRef } from "react";

interface PotencyDisplayProps {
    potency: string;
    className?: string;
}

// Use forwardRef with proper type annotations
const PotencyDisplay = forwardRef<HTMLDivElement, PotencyDisplayProps>(function PotencyDisplay(
    { potency, className },
    ref
) {
    return (
        <div ref={ref} className={`flex items-center gap-1 ${className ?? ""}`}>
            <span className="text-sm font-medium">{potency}</span>
        </div>
    );
});

interface PotencyProps {
    potency: string;
    isStatic?: boolean;
    className?: string;
}

export default function Potency({ potency, isStatic = false, className }: PotencyProps) {
    if (isStatic) {
        return <PotencyDisplay potency={potency} className={className} />;
    }

    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <PotencyDisplay potency={`${potency}*`} className={className} />
            </TooltipTrigger>
            <TooltipContent className="max-w-xs text-sm" side="top" sideOffset={5}>
                * THC levels may vary by batch. Each batch is tested and will be accompanied by a
                Certificate of Analysis containing THC levels.
            </TooltipContent>
        </Tooltip>
    );
}
