"use client";

import { Sheet, SheetContent } from "@/components/shared/ui/sheet";
import { ShopFilterSectionContent } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { useRouter } from "next/navigation";
import { memo, useCallback, useEffect, useState } from "react";
import FilterSidebarClient from "./filter-sidebar-client";

interface FilterSidebarProps {
    filterSidebarContents: ShopFilterSectionContent[];
    className?: string;
    isOpenExternal?: boolean;
    // eslint-disable-next-line no-unused-vars
    onOpenChange?: (isOpen: boolean) => void;
}

const FilterSidebar: React.FC<FilterSidebarProps> = memo(
    ({ filterSidebarContents, className = "", isOpenExternal = false, onOpenChange }) => {
        const [isOpen, setIsOpen] = useState(false);
        const clearAllFilters = ShopStore.use.clearAllFilters();
        const router = useRouter();

        useEffect(() => {
            if (isOpenExternal !== undefined) {
                setIsOpen(isOpenExternal);
            }
        }, [isOpenExternal]);

        const handleOpenChange = useCallback(
            (open: boolean) => {
                setIsOpen(open);
                onOpenChange?.(open);
            },
            [onOpenChange]
        );

        const clearFilters = useCallback(() => {
            clearAllFilters(router);
        }, [clearAllFilters, router]);

        const DesktopSidebar = (
            <div className={`mb-20 hidden w-64 md:block ${className} bg-white`}>
                <FilterSidebarClient
                    filterSidebarContents={filterSidebarContents}
                    clearFilters={clearFilters}
                />
            </div>
        );

        const MobileSidebar = (
            <div className="md:hidden">
                <Sheet open={isOpen} onOpenChange={handleOpenChange}>
                    <SheetContent side="left" className="w-full p-0 text-3xl">
                        <FilterSidebarClient
                            filterSidebarContents={filterSidebarContents}
                            clearFilters={clearFilters}
                        />
                    </SheetContent>
                </Sheet>
            </div>
        );

        return (
            <>
                {MobileSidebar}
                {DesktopSidebar}
            </>
        );
    }
);

FilterSidebar.displayName = "FilterSidebar";

export default FilterSidebar;
