import { Card, CardContent } from "@/components/shared/ui/card";
import { Skeleton } from "@/components/shared/ui/skeleton";
export function ProductCardSkeleton() {
    return (
        <Card className="flex h-[400px] flex-col bg-white">
            <div className="relative h-48 w-full overflow-hidden rounded-t-lg bg-gray-100">
                <Skeleton className="h-full w-full" />
            </div>

            <CardContent className="flex flex-col gap-3 p-4">
                <Skeleton className="h-5 w-20" />

                <div className="space-y-1">
                    <Skeleton className="h-8 w-48" />
                    <div className="flex items-center gap-2">
                        <Skeleton className="h-6 w-16 rounded-full" />
                        <Skeleton className="h-4 w-20" />
                        <Skeleton className="h-4 w-16" />
                    </div>
                </div>

                <div className="mt-2 flex items-baseline gap-2">
                    <Skeleton className="h-6 w-20" />
                    <Skeleton className="h-4 w-24" />
                </div>

                <div className="flex items-center gap-2">
                    <Skeleton className="h-4 w-24" />
                </div>

                <div className="mt-auto pt-2">
                    <Skeleton className="h-10 w-full rounded-md" />
                </div>
            </CardContent>
        </Card>
    );
}
