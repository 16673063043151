import { formatCurrency } from "@/lib/library";
import { ShopPrice } from "@/lib/shop/shop-types";

interface PriceDisplayProps {
    price: ShopPrice | null;
    className?: string;
    variant?: "default" | "medium" | "large";
}

const ProductPriceDisplay = ({ price, className = "", variant = "default" }: PriceDisplayProps) => {
    if (!price) return null;

    const styles = {
        default: {
            container: "-space-y-1",
            unitPrice: "text-base font-semibold",
            fullPrice: "text-xs",
            price: "text-sm text-gray-500",
            discount: "text-xs font-medium",
            label: "text-xs text-gray-500"
        },
        medium: {
            container: "-space-y-0.5",
            unitPrice: "text-xl font-bold",
            fullPrice: "text-sm",
            price: "text-sm text-gray-500",
            discount: "text-sm font-medium",
            label: "text-sm text-gray-500"
        },
        large: {
            container: "-space-y-1",
            unitPrice: "text-3xl font-bold",
            fullPrice: "text-xl",
            price: "text-lg text-gray-500",
            discount: "text-sm font-medium",
            label: "text-sm text-gray-500"
        }
    }[variant];

    const hasDiscount = price.unitDiscount > 0;

    return (
        <div
            className={`flex flex-col ${styles.container} ${className} h-fit w-fit items-end gap-y-1`}
        >
            <div className="flex flex-wrap items-end justify-between gap-x-1 gap-y-0.5 ">
                <div className="flex flex-col justify-start ">
                    <div className="flex flex-col items-start ">
                        <div className={`${styles.unitPrice} flex flex-row items-baseline`}>
                            {formatCurrency(price.unitPrice)}{" "}
                            <span className={styles.label}>/unit</span>
                        </div>
                        {hasDiscount && (
                            <div className="flex flex-wrap-reverse items-center whitespace-nowrap text-left ">
                                <span className={`${styles.fullPrice} text-gray-500 line-through`}>
                                    {formatCurrency(price.unitFullPrice)} /unit
                                </span>
                                <span className={`${styles.discount} ml-1 text-green-600`}>
                                    - {formatCurrency(price.unitDiscount)}
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-row items-baseline justify-start gap-1 whitespace-nowrap ">
                        <span className={styles.price}>{formatCurrency(price.casePrice)}</span>
                        <span className={styles.label}>/case</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductPriceDisplay;
