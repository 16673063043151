import { Input } from "@/components/shared/ui/input";
import { Label } from "@/components/shared/ui/label";
import { ShopInputFilter } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { useRouter } from "next/navigation";

type InputFilterOptionProps = {
    item: ShopInputFilter;
    multiSelect?: boolean;
};

export default function InputFilterOption({ item, multiSelect }: InputFilterOptionProps) {
    const getFilter = ShopStore.use.getFilter();
    const filters = ShopStore.use.filters();
    const setFilter = ShopStore.use.setFilter();
    const removeFilter = ShopStore.use.removeFilter();

    const router = useRouter();

    const handleFilterChange = (filterID: string, value: string, multiSelect?: boolean) => {
        if (multiSelect) {
            const currentValues = (getFilter(filterID) || []) as Array<string>;
            currentValues.push(String(value));
            if (currentValues.length === 0) {
                removeFilter(filterID, router);
            } else {
                setFilter(filterID, currentValues, router);
            }
        } else {
            if (value === "") {
                removeFilter(filterID, router);
            } else {
                setFilter(filterID, value, router);
            }
        }
    };

    const itemKey = `${item.filterID}-${item.value}`;
    const value = (filters[item.filterID] as string) || "";

    return (
        <div
            className="mt-1 flex flex-row items-center justify-start gap-2 space-x-2"
            key={itemKey}
        >
            <Label
                htmlFor={itemKey}
                className="flex w-fit cursor-pointer flex-row items-center justify-between gap-2"
            >
                {item.displayName}:
                {item.imageURL ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                        key={`image-${itemKey}`}
                        src={item.imageURL}
                        alt={`${item.displayName} image`}
                        className="h-20 w-auto md:h-6"
                    />
                ) : null}
            </Label>
            <Input
                id={itemKey}
                type={item.dataType === "number" ? "number" : "text"}
                className="h-6 w-20"
                value={value}
                min={0}
                onChange={(e) => {
                    handleFilterChange(item.filterID, e.target.value, multiSelect);
                }}
            />
        </div>
    );
}
