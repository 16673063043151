"use client";

import { ProductGroup, ShopMenuPromotion } from "@/lib/shop/shop-types";
import { debounce } from "lodash";
import Image from "next/image";
import { useCallback, useEffect, useMemo, useState } from "react";
import MenuPromotionCarousel from "../../shared/menu-promotions";

type GroupHeaderProps = {
    group: ProductGroup;
    className?: string;
};

type FetchState = {
    isLoading: boolean;
    error: string | null;
    data: ShopMenuPromotion[] | null;
};

export default function GroupHeader({ group, className = "" }: GroupHeaderProps) {
    const [fetchState, setFetchState] = useState<FetchState>({
        isLoading: false,
        error: null,
        data: null
    });

    const fetchPromotions = useCallback(async (supplierID: number, state: string) => {
        setFetchState((prev) => ({ ...prev, isLoading: true }));

        try {
            const response = await fetch(
                `/api/menuPromotions?supplierID=${supplierID}&state=${state}`,
                {
                    headers: {
                        Accept: "application/json"
                    }
                }
            );

            if (!response.ok) {
                throw new Error(`Failed to fetch promotions: ${response.statusText}`);
            }

            const data = await response.json();
            setFetchState({
                isLoading: false,
                error: null,
                data
            });
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : "Failed to fetch promotions";
            setFetchState({
                isLoading: false,
                error: errorMessage,
                data: null
            });
        }
    }, []);

    const debouncedFetch = useCallback(
        (supplierID: number, state: string) => {
            const debouncedFn = debounce(() => {
                fetchPromotions(supplierID, state);
            }, 300);
            debouncedFn();
            return debouncedFn;
        },
        [fetchPromotions]
    );

    useEffect(() => {
        if (!group.supplierID || !group.state) {
            setFetchState((prev) => ({ ...prev, data: [] }));
            return;
        }

        const debouncedFn = debouncedFetch(group.supplierID, group.state);

        return () => {
            debouncedFn.cancel();
        };
    }, [group.supplierID, group.state, debouncedFetch]);

    // Memoize the image component
    const imageComponent = useMemo(() => {
        if (!group.imageURL) return null;

        return (
            <div className="relative h-40 w-48">
                <Image
                    src={group.imageURL}
                    alt={group.header}
                    fill
                    className="object-contain"
                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    priority={true}
                />
            </div>
        );
    }, [group.imageURL, group.header]);

    // Memoize the header text
    const headerText = useMemo(() => {
        if (group.imageURL) return null;
        return <h2 className="p-4 text-2xl font-semibold text-gray-900">{group.header}</h2>;
    }, [group.imageURL, group.header]);

    // Memoize the promotions carousel
    const promotionsCarousel = useMemo(() => {
        if (group.type !== "supplier" || !fetchState.data || fetchState.data.length === 0) {
            return null;
        }

        return (
            <div className="w-full">
                <MenuPromotionCarousel menuPromotions={fetchState.data} showTitle={false} />
            </div>
        );
    }, [group.type, fetchState.data]);

    // Memoize the main container class
    const containerClassName = useMemo(() => {
        return `relative mb-6 w-full ${className}`;
    }, [className]);

    return (
        <div className={containerClassName}>
            <div className="flex flex-col items-start gap-6 md:flex-row md:justify-between">
                <div className="flex items-center gap-4 rounded-lg border bg-white p-1 shadow-sm">
                    {imageComponent || headerText}
                </div>
                {promotionsCarousel}
            </div>
        </div>
    );
}
