"use client";

import CaseUnitInput from "@/components/features/cart-edit/case-unit-input";
import DailySalesDisplay from "@/components/shared/product/daily-sales-display";
import DOIDisplay from "@/components/shared/product/doi-display";
import RetailerInventoryDisplay from "@/components/shared/product/retailer-inventory";
import { ShopCartTransaction } from "@/lib/cart/cart-types";
import { TARGET_DOI_FILTER_ID } from "@/lib/shop/shop-constants";
import { ShopRetailerInventory } from "@/lib/shop/shop-types";
import { useCartStore } from "@/state-management/stores/cart-store";
import { ShopStore } from "@/state-management/stores/shop-store";
import { memo, useEffect, useState } from "react";

interface ProductRowCartProps {
    productID: number;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    retailerInventory: ShopRetailerInventory | null;
    minOrderUnits: number;
    className?: string;
}

interface MemoizedCaseUnitInputProps {
    productID: number;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    initialCases: number;
    initialUnits: number;
    minOrderUnits: number;
}

const MemoizedCaseUnitInput = memo(function MemoizedCaseUnitInput({
    productID,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    initialCases,
    initialUnits,
    minOrderUnits
}: MemoizedCaseUnitInputProps) {
    return (
        <CaseUnitInput
            productID={productID}
            wholesaleUnitsPerCase={wholesaleUnitsPerCase}
            sellByCaseOnly={sellByCaseOnly}
            promotionID={null}
            initialQuantity={{
                cases: initialCases,
                units: initialUnits
            }}
            minOrderUnits={minOrderUnits}
        />
    );
});

export default function ProductRowCart({
    productID,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    retailerInventory,
    minOrderUnits,
    className
}: ProductRowCartProps) {
    const [baseTransaction, setBaseTransaction] = useState<ShopCartTransaction | undefined>();
    const [currentUnits, setCurrentUnits] = useState(0);
    const getFilter = ShopStore.use.getFilter();

    useEffect(() => {
        const unsubscribe = useCartStore
            .getState()
            .subscribeToProduct(productID, (transactions) => {
                if (transactions && transactions.length > 0) {
                    let currentUnits = 0;
                    transactions.forEach((transaction) => {
                        if (transaction.manualPromotionID === null) {
                            setBaseTransaction(transaction);
                        }
                        currentUnits += transaction.numUnits;
                    });

                    setCurrentUnits(currentUnits);
                } else {
                    setBaseTransaction(undefined);
                    setCurrentUnits(0);
                }
            });

        return () => {
            unsubscribe();
        };
    }, [productID]);

    const initialCases = baseTransaction
        ? Math.floor(baseTransaction.numUnits / wholesaleUnitsPerCase)
        : 0;
    const initialUnits = baseTransaction ? baseTransaction.numUnits % wholesaleUnitsPerCase : 0;

    return (
        <div
            className={`${className} flex h-full w-full flex-row justify-between gap-2  md:h-fit md:items-center md:gap-4`}
        >
            <div className="text-md flex h-full w-full flex-col gap-2 md:w-fit ">
                {retailerInventory ? (
                    <div className="flex flex-col">
                        <div className="flex  items-center justify-start gap-2 ">
                            <RetailerInventoryDisplay
                                inventory={retailerInventory.inventory}
                                lastInventoryDate={retailerInventory.lastInventoryDate}
                            />
                            <DOIDisplay
                                inventory={retailerInventory.inventory}
                                dailySales={retailerInventory.dailySales}
                                currentDOI={retailerInventory.DOI}
                                currentCartUnits={currentUnits}
                                productID={productID}
                                wholesaleUnitsPerCase={wholesaleUnitsPerCase}
                                targetDOI={
                                    getFilter(TARGET_DOI_FILTER_ID)
                                        ? Number(getFilter(TARGET_DOI_FILTER_ID) as string)
                                        : undefined
                                }
                                className="text-sm"
                            />
                        </div>
                        <DailySalesDisplay
                            dailySales={retailerInventory.dailySales}
                            className="text-xs"
                        />
                    </div>
                ) : (
                    <div className="text-md h-full w-[50%] text-center text-gray-500 md:w-[151px]">
                        No POS Data
                    </div>
                )}
            </div>
            <div className="h-full items-end">
                <MemoizedCaseUnitInput
                    key={`${productID}-${currentUnits}`}
                    productID={productID}
                    wholesaleUnitsPerCase={wholesaleUnitsPerCase}
                    sellByCaseOnly={sellByCaseOnly ?? false}
                    initialCases={initialCases}
                    initialUnits={initialUnits}
                    minOrderUnits={minOrderUnits}
                />
            </div>
        </div>
    );
}
