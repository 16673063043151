import Grid from "@/components/shared/grid";
import { GRID_CONFIG, RESULTS_PER_PAGE } from "@/lib/shop/shop-constants";
import { ShopBrowsingData, ShopLayout } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { memo } from "react";
import ProductCard from "./product-card/product-card";
import { ProductCardSkeleton } from "./product-card/product-card-loading";
import ProductRow from "./product-row/product-row";
import { ProductRowSkeleton } from "./product-row/product-row-loading";

const MemoizedProductCard = memo(ProductCard);
const MemoizedProductRow = memo(ProductRow);

const MemoizedGridItem = memo(({ shopData }: { shopData: ShopBrowsingData }) => (
    <Grid.Item className="animate-fadeIn">
        <MemoizedProductCard shopData={shopData} />
    </Grid.Item>
));

MemoizedGridItem.displayName = "MemoizedGridItem";

const LoadingRow = memo(({ layout }: { layout: ShopLayout }) => {
    const skeletons = [...Array(RESULTS_PER_PAGE)].map((_, i) =>
        layout === "list" ? (
            <ProductRowSkeleton key={`list-${i}`} />
        ) : (
            <ProductCardSkeleton key={`grid-${i}`} />
        )
    );

    if (layout === "list") {
        return <div className="flex flex-col">{skeletons}</div>;
    }

    return <Grid className={`gap-4 ${Object.values(GRID_CONFIG).join(" ")}`}>{skeletons}</Grid>;
});

LoadingRow.displayName = "LoadingRow";

interface DynamicProductGridProps {
    products: ShopBrowsingData[];
    isFetchingNextPage: boolean;
    isLoading: boolean;
}

const DynamicProductGrid = memo(
    ({ products, isFetchingNextPage, isLoading }: DynamicProductGridProps) => {
        const layout = ShopStore.use.layout();

        if (isLoading) {
            return <LoadingRow layout={layout} />;
        }

        if (!products?.length) {
            return <div className="text-gray-500">No products found</div>;
        }

        return (
            <div className="h-fit">
                {layout === "list" ? (
                    <div className="flex flex-col gap-0 overflow-hidden rounded-lg border">
                        {products.map((shopData: ShopBrowsingData) => (
                            <MemoizedProductRow key={`${shopData.productID}`} shopData={shopData} />
                        ))}
                    </div>
                ) : (
                    <Grid className={`gap-4 ${Object.values(GRID_CONFIG).join(" ")}`}>
                        {products.map((shopData: ShopBrowsingData) => (
                            <MemoizedGridItem key={`${shopData.productID}`} shopData={shopData} />
                        ))}
                    </Grid>
                )}
                {isFetchingNextPage && <LoadingRow layout={layout} />}
            </div>
        );
    }
);

DynamicProductGrid.displayName = "DynamicProductGrid";

export default DynamicProductGrid;
