import { Badge } from "@/components/shared/ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/shared/ui/tooltip";
import { ShopInventory } from "@/lib/shop/shop-types";
import { AlertTriangle, CheckCircle2 } from "lucide-react";

export default function AvailableInventoryBadge({
    inventory,
    wholesaleUnitsPerCase,
    sellByCaseOnly = true,
    classes
}: {
    inventory: ShopInventory;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly?: boolean;
    classes?: string;
}) {
    const availableCases = Number(inventory.availableUnits / wholesaleUnitsPerCase);

    const inStock = availableCases >= 50;

    const renderInventoryDetails = () => {
        if (inStock) {
            return "In Stock";
        } else if (
            availableCases < 1 &&
            Number(inventory.availableUnits) > (sellByCaseOnly ? wholesaleUnitsPerCase : 0)
        ) {
            return "< 1 Case";
        } else if (availableCases < 10 && Number(inventory.availableUnits) > 0) {
            return "< 10 Cases";
        } else if (availableCases < 25 && Number(inventory.availableUnits) > 0) {
            return "< 25 Cases";
        } else if (availableCases < 50 && Number(inventory.availableUnits) > 0) {
            return "< 50 Cases";
        } else {
            return "Out of Stock";
        }
    };

    const getBadgeStyles = () => {
        if (inStock) {
            return "bg-indigo-50 text-indigo-700 border-indigo-200";
        } else if (availableCases < 10 && Number(inventory.availableUnits) > 0) {
            return "bg-orange-50 text-orange-700 border-orange-200";
        } else if (availableCases < 25 && Number(inventory.availableUnits) > 0) {
            return "bg-yellow-50 text-yellow-700 border-yellow-200";
        } else if (availableCases < 50 && Number(inventory.availableUnits) > 0) {
            return "bg-blue-50 text-blue-700 border-blue-200";
        } else {
            return "bg-red-50 text-red-700 border-red-200";
        }
    };

    const getIcon = () => {
        if (inStock) {
            return <CheckCircle2 className="h-3 w-3" />;
        } else if (availableCases < 25 && Number(inventory.availableUnits) > 0) {
            return <AlertTriangle className="h-3 w-3" />;
        }
        return null;
    };

    const badge = (
        <Badge
            variant="outline"
            className={`${getBadgeStyles()} flex items-center gap-1 ${classes ? classes : ""} h-fit w-fit whitespace-nowrap`}
        >
            {getIcon()}
            {renderInventoryDetails()}
        </Badge>
    );

    return (
        <Tooltip>
            <TooltipTrigger>{badge}</TooltipTrigger>
            <TooltipContent>
                <p>KSS Warehouse Inventory</p>
            </TooltipContent>
        </Tooltip>
    );
}
