import { Label } from "@/components/shared/ui/label";
import { Slider } from "@/components/shared/ui/slider";
import { ShopRangeFilter } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useRef, useState } from "react";

type RangeFilterOptionProps = {
    item: ShopRangeFilter;
};

export default function RangeFilterOption({ item }: RangeFilterOptionProps) {
    const getFilter = ShopStore.use.getFilter();
    const setFilter = ShopStore.use.setFilter();
    const removeFilter = ShopStore.use.removeFilter();
    const router = useRouter();

    // Track if the component is being interacted with
    const [isInteracting, setIsInteracting] = useState(false);

    // Track if the slider is being dragged
    const [isDragging, setIsDragging] = useState(false);

    // References to input elements for focus handling
    const minInputRef = useRef<HTMLInputElement>(null);
    const maxInputRef = useRef<HTMLInputElement>(null);

    // Reference to the container to detect when focus leaves the component
    const containerRef = useRef<HTMLDivElement>(null);

    // Get the current filter value as a range [min, max]
    const currentFilterValue = getFilter(item.filterID) as string | undefined;
    let initialRange: [number, number] = [item.minValue, item.maxValue];

    if (currentFilterValue) {
        try {
            // Try to parse the string as JSON array
            const parsedRange = JSON.parse(currentFilterValue);
            if (Array.isArray(parsedRange) && parsedRange.length === 2) {
                initialRange = parsedRange as [number, number];
            }
        } catch (e) {
            // If parsing fails, use default range
            console.error("Failed to parse range filter value", e);
        }
    }

    const [range, setRange] = useState<[number, number]>(initialRange);

    // Keep track of the last range that was sent to the filter
    const [lastAppliedRange, setLastAppliedRange] = useState<[number, number]>(initialRange);

    useEffect(() => {
        // Update local state when the filter changes externally
        if (currentFilterValue) {
            try {
                const parsedRange = JSON.parse(currentFilterValue);
                if (Array.isArray(parsedRange) && parsedRange.length === 2) {
                    setRange(parsedRange as [number, number]);
                    setLastAppliedRange(parsedRange as [number, number]);
                }
            } catch (e) {
                // If parsing fails, keep current range
            }
        } else {
            setRange([item.minValue, item.maxValue]);
            setLastAppliedRange([item.minValue, item.maxValue]);
        }
    }, [currentFilterValue, item.minValue, item.maxValue]);

    const validateRange = useCallback(
        (newRange: [number, number]): [number, number] => {
            // Ensure min doesn't exceed max and max doesn't go below min
            return [
                Math.max(Math.min(newRange[0], newRange[1]), item.minValue),
                Math.min(Math.max(newRange[1], newRange[0]), item.maxValue)
            ];
        },
        [item.minValue, item.maxValue]
    );

    const applyFilter = useCallback(() => {
        const validatedRange = validateRange(range);

        // Only update if the range has actually changed
        if (
            validatedRange[0] !== lastAppliedRange[0] ||
            validatedRange[1] !== lastAppliedRange[1]
        ) {
            if (validatedRange[0] === item.minValue && validatedRange[1] === item.maxValue) {
                removeFilter(item.filterID, router);
            } else {
                setFilter(item.filterID, JSON.stringify(validatedRange), router);
            }

            // Update our last applied range
            setLastAppliedRange(validatedRange);
        }

        // Make sure the UI is showing the validated range
        setRange(validatedRange);
    }, [
        range,
        lastAppliedRange,
        validateRange,
        item.minValue,
        item.maxValue,
        item.filterID,
        removeFilter,
        setFilter,
        router
    ]);

    // Set up global event handlers for both mouse and touch interactions
    useEffect(() => {
        const handleGlobalPointerUp = () => {
            if (isDragging) {
                applyFilter();
                setIsDragging(false);
                setIsInteracting(false);
            }
        };

        const handleGlobalPointerDown = (e: MouseEvent | TouchEvent) => {
            // Check if we're clicking/touching outside the container
            if (
                isInteracting &&
                containerRef.current &&
                e.target instanceof Node &&
                !containerRef.current.contains(e.target as Node)
            ) {
                applyFilter();
                setIsInteracting(false);
            }
        };

        // Add all relevant event listeners for both desktop and mobile
        document.addEventListener("pointerup", handleGlobalPointerUp);
        document.addEventListener("touchend", handleGlobalPointerUp);
        document.addEventListener("mousedown", handleGlobalPointerDown);
        document.addEventListener("touchstart", handleGlobalPointerDown);

        return () => {
            // Clean up all event listeners
            document.removeEventListener("pointerup", handleGlobalPointerUp);
            document.removeEventListener("touchend", handleGlobalPointerUp);
            document.removeEventListener("mousedown", handleGlobalPointerDown);
            document.removeEventListener("touchstart", handleGlobalPointerDown);
        };
    }, [isInteracting, isDragging, applyFilter]);

    const handleValueChange = (newValue: number[]) => {
        setIsInteracting(true);
        setIsDragging(true);

        const newRange: [number, number] = [
            newValue[0] ?? item.minValue,
            newValue[1] ?? item.maxValue
        ];

        setRange(newRange);
    };

    // Handle slider interaction events for both mouse and touch
    const handleSliderPointerDown = () => {
        setIsDragging(true);
        setIsInteracting(true);
    };

    const handleSliderPointerUp = () => {
        if (isDragging) {
            applyFilter();
            setIsDragging(false);
            setIsInteracting(false);
        }
    };

    const handleInputChange = (index: 0 | 1, value: string) => {
        setIsInteracting(true);

        const newValue =
            value === "" ? (index === 0 ? item.minValue : item.maxValue) : Number(value);

        const newRange = [...range] as [number, number];
        newRange[index] = newValue;

        setRange(newRange);
    };

    const handleInputBlur = () => {
        applyFilter();
        setIsInteracting(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            // Blur the current input
            e.currentTarget.blur();

            // Apply the filter
            applyFilter();
            setIsInteracting(false);
        }
    };

    const itemKey = `${item.filterID}-range`;

    return (
        <div ref={containerRef} className="mt-4 flex flex-col gap-1 px-1 pb-4">
            <div className="mb-2 flex items-center justify-between">
                <Label
                    htmlFor={itemKey}
                    className="flex cursor-pointer items-center gap-2 text-lg md:text-sm"
                >
                    {item.displayName}
                    {item.imageURL ? (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img
                            src={item.imageURL}
                            alt={`${item.displayName} image`}
                            className="h-6 w-auto"
                        />
                    ) : null}
                </Label>
                <button
                    type="button"
                    onClick={() => {
                        setRange([item.minValue, item.maxValue]);
                        removeFilter(item.filterID, router);
                        setLastAppliedRange([item.minValue, item.maxValue]);
                    }}
                    className={`text-lg hover:text-primary md:text-xs ${
                        range[0] === item.minValue && range[1] === item.maxValue
                            ? "cursor-not-allowed text-muted-foreground"
                            : ""
                    }`}
                >
                    Reset
                </button>
            </div>
            <div className="flex items-center justify-between gap-2">
                <input
                    ref={minInputRef}
                    type="number"
                    inputMode="decimal"
                    value={range[0]}
                    min={item.minValue}
                    max={item.maxValue}
                    step={item.step || 1}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => handleInputChange(0, e.target.value)}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    className="h-10 w-20 rounded-md border px-2 text-lg focus:outline-none focus:ring-2 focus:ring-primary md:h-6 md:text-sm"
                />
                <span className="text-sm">-</span>
                <input
                    ref={maxInputRef}
                    type="number"
                    inputMode="decimal"
                    value={range[1]}
                    min={item.minValue}
                    max={item.maxValue}
                    step={item.step || 1}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => handleInputChange(1, e.target.value)}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    className="h-10 w-20 rounded-md border px-2 text-lg focus:outline-none focus:ring-2 focus:ring-primary md:h-6 md:text-sm"
                />
            </div>
            <Slider
                id={itemKey}
                min={item.minValue}
                max={item.maxValue}
                step={item.step || 1}
                value={range}
                onValueChange={handleValueChange}
                onPointerDown={handleSliderPointerDown}
                onPointerUp={handleSliderPointerUp}
                onTouchStart={handleSliderPointerDown}
                onTouchEnd={handleSliderPointerUp}
                className="py-3"
            />
        </div>
    );
}
