"use client";

import Grid from "@/components/shared/grid";
import { Skeleton } from "@/components/shared/ui/skeleton";
import { GRID_CONFIG, RESULTS_PER_PAGE } from "@/lib/shop/shop-constants";
import { GroupedProducts, ProductGroup, ShopLayout } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { memo } from "react";
import DynamicProductGrid from "./dynamic-product-grid";
import GroupHeader from "./group-header";
import { ProductCardSkeleton } from "./product-card/product-card-loading";
import { ProductRowSkeleton } from "./product-row/product-row-loading";

export interface DynamicGridWithHeadersProps {
    groupedProducts: GroupedProducts;
    isFetchingNextPage: boolean;
    isLoading: boolean;
}

const MemoizedGroupHeader = memo(({ group }: { group: ProductGroup }) => (
    <GroupHeader group={group} />
));
MemoizedGroupHeader.displayName = "MemoizedGroupHeader";

const MemoizedDynamicProductGrid = memo(
    ({
        products,
        isLoading,
        isFetchingNextPage
    }: {
        products: ProductGroup["products"];
        isLoading: boolean;
        isFetchingNextPage: boolean;
    }) => (
        <DynamicProductGrid
            products={products}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
        />
    )
);
MemoizedDynamicProductGrid.displayName = "MemoizedDynamicProductGrid";

const MemoizedSection = memo(
    ({
        group,
        isLoading,
        isFetchingNextPage
    }: {
        group: ProductGroup;
        isLoading: boolean;
        isFetchingNextPage: boolean;
    }) => (
        <section className="p-6">
            <MemoizedGroupHeader group={group} />
            <MemoizedDynamicProductGrid
                products={group.products}
                isLoading={isLoading}
                isFetchingNextPage={isFetchingNextPage}
            />
        </section>
    )
);
MemoizedSection.displayName = "MemoizedSection";

const LoadingRow = memo(({ layout }: { layout: ShopLayout }) => {
    const skeletons = [...Array(RESULTS_PER_PAGE)].map((_, i) =>
        layout === "list" ? (
            <ProductRowSkeleton key={`list-${i}`} />
        ) : (
            <ProductCardSkeleton key={`grid-${i}`} />
        )
    );

    if (layout === "list") {
        return (
            <section className="p-6">
                <div className="mb-2 flex items-center space-x-2">
                    <Skeleton className="h-40 w-40 rounded border" />
                </div>
                <div className="flex flex-col">{skeletons}</div>
            </section>
        );
    }

    return (
        <section className="p-6">
            <div className="mb-2 flex items-center space-x-2">
                <Skeleton className="h-40 w-40 rounded border" />
            </div>
            <Grid className={`gap-4 ${Object.values(GRID_CONFIG).join(" ")}`}>{skeletons}</Grid>
        </section>
    );
});

LoadingRow.displayName = "LoadingRow";

export const DynamicGridWithHeaders = memo(
    ({ groupedProducts, isFetchingNextPage, isLoading }: DynamicGridWithHeadersProps) => {
        const layout = ShopStore.use.layout();

        if (isLoading) {
            return <LoadingRow layout={layout} />;
        }

        return (
            <div className="space-y-8">
                {groupedProducts.map((group: ProductGroup, index: number) => (
                    <MemoizedSection
                        key={`${index}-${group.header}`}
                        group={group}
                        isLoading={isLoading}
                        isFetchingNextPage={
                            isFetchingNextPage && index === groupedProducts.length - 1
                        }
                    />
                ))}
            </div>
        );
    }
);
DynamicGridWithHeaders.displayName = "DynamicGridWithHeaders";

export default DynamicGridWithHeaders;
