"use client";

import { GroupedProducts, ShopBrowsingData } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { useInfiniteQuery } from "@tanstack/react-query";
import _ from "lodash";
import { ArrowUp } from "lucide-react";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import DynamicGridWithHeaders from "./dynamic-grid-with-headers";
import DynamicProductGrid from "./dynamic-product-grid";

export default function InfiniteScrollFetch({
    initialData,
    state,
    customerID
}: {
    initialData?: ShopBrowsingData[];
    state: string;
    customerID: number | null;
}) {
    const filters = ShopStore.use.filters();
    const sort = ShopStore.use.sort();
    const fetchingRef = useRef(false);

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: "800px",
        // Delay disconnecting the observer to handle fast scrolling
        delay: 100
    });

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
        queryKey: [
            "products",
            state,
            sort.sortKey,
            sort.reverse,
            JSON.stringify(filters),
            customerID
        ],
        queryFn: async ({ pageParam = 1 }) => {
            const searchParams = new URLSearchParams({
                page: pageParam.toString(),
                state,
                sortKey: sort.sortKey,
                reverse: sort.reverse.toString(),
                filters: JSON.stringify(filters)
            });

            const response = await fetch(`/api/shop?${searchParams.toString()}`);
            const data = await response.json();
            return data;
        },
        ...(initialData
            ? {
                  initialData: {
                      pages: [initialData],
                      pageParams: [0]
                  }
              }
            : {}),
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage?.length > 0 ? allPages.length : undefined;
        },
        staleTime: 1000 * 60,
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const handleFetch = async () => {
            if (inView && hasNextPage && !fetchingRef.current) {
                fetchingRef.current = true;
                await fetchNextPage();
                fetchingRef.current = false;
            }
        };

        handleFetch();

        return () => {
            fetchingRef.current = false;
        };
    }, [inView, fetchNextPage, hasNextPage]);

    const products = data?.pages.flatMap((page) => page) || [];

    return (
        <div className="h-fit">
            {sort.sortKey === "supplier" ? (
                <DynamicGridWithHeaders
                    groupedProducts={groupProductsBySupplier(products, sort.reverse)}
                    isLoading={isLoading}
                    isFetchingNextPage={isFetchingNextPage}
                />
            ) : sort.sortKey === "category" ? (
                <DynamicGridWithHeaders
                    groupedProducts={groupProductsByCategory(products, sort.reverse)}
                    isLoading={isLoading}
                    isFetchingNextPage={isFetchingNextPage}
                />
            ) : (
                <section className="p-6">
                    <DynamicProductGrid
                        products={products}
                        isFetchingNextPage={isFetchingNextPage}
                        isLoading={isLoading}
                    />
                </section>
            )}

            <div ref={ref} className="mt-30 h-10">
                {hasNextPage ? (
                    <div className="text-center text-gray-500">Loading More...</div>
                ) : (
                    <button
                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                        className="mx-auto mt-5 block rounded-lg bg-gray-100 p-6 text-center text-sm text-gray-600 transition-colors hover:bg-gray-200"
                    >
                        No More Products. Back to top <ArrowUp className="inline-block" />
                    </button>
                )}
            </div>
        </div>
    );
}

const groupProductsByCategory = (
    products: ShopBrowsingData[],
    reverse: boolean
): GroupedProducts => {
    const grouped = _.groupBy(products, (product) => product.categoryName || "Other");

    return Object.entries(grouped)
        .sort(([headerA], [headerB]) =>
            reverse ? headerB.localeCompare(headerA) : headerA.localeCompare(headerB)
        )
        .map(([header, products]) => ({
            header,
            products,
            state: products[0]?.state || "",
            type: "category"
        }));
};

const groupProductsBySupplier = (
    products: ShopBrowsingData[],
    reverse: boolean
): GroupedProducts => {
    const grouped = _.groupBy(products, (product) => product.supplierName || "Other");

    return Object.entries(grouped)
        .sort(([headerA], [headerB]) =>
            reverse ? headerB.localeCompare(headerA) : headerA.localeCompare(headerB)
        )
        .map(([header, products]) => ({
            header,
            type: "supplier",
            imageURL: products[0]?.supplierLogoURL,
            state: products[0]?.state || "",
            supplierID: products[0]?.supplierID,
            products
        }));
};
