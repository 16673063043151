import Blend from "@/components/shared/blend";
import AvailableInventoryBadge from "@/components/shared/product/available-inventory-badge";
import Potency from "@/components/shared/product/potency";
import ProductPriceDisplay from "@/components/shared/product/product-price-display";
import { Badge } from "@/components/shared/ui/badge";
import { ShopBrowsingData } from "@/lib/shop/shop-types";
import { Star, SwatchBook, Tag } from "lucide-react";
import Link from "next/link";
import { memo } from "react";
import ProductRowCart from "./product-row-cart";

interface ProductRowProps {
    shopData: ShopBrowsingData;
}

const SaleBadge = memo(() => (
    <Badge className="h-6 w-fit gap-1 bg-emerald-400 text-sm" variant={"defaultNoHover"}>
        <Tag className="h-4 w-4" />
        Sale
    </Badge>
));
SaleBadge.displayName = "SaleBadge";

const SampleBadge = memo(() => (
    <Badge className="h-6 w-fit gap-1 bg-violet-600 text-sm" variant={"defaultNoHover"}>
        <SwatchBook className="h-4 w-4" />
        Sample
    </Badge>
));
SampleBadge.displayName = "SampleBadge";

const NewBadge = memo(function NewBadge() {
    return (
        <Badge className="h-6 w-fit gap-1 bg-blue-500 text-sm" variant={"defaultNoHover"}>
            <Star className="h-4 w-4" />
            New
        </Badge>
    );
});
NewBadge.displayName = "NewBadge";

// Memoized product information section
const ProductTitle = memo(
    ({
        productNameDisplay,
        shopData
    }: {
        productNameDisplay: string;
        shopData: ShopBrowsingData;
    }) => (
        <div className="flex h-full w-fit flex-col gap-1 overflow-hidden">
            <div className="flex w-full flex-row items-baseline justify-start gap-2 whitespace-nowrap md:w-fit md:justify-items-start ">
                {shopData.price?.isSale && !shopData.isSample ? <SaleBadge /> : null}
                {shopData.isSample ? <SampleBadge /> : null}
                {shopData.isNew && !shopData.isSample ? <NewBadge /> : null}
                <div className="flex w-fit flex-wrap items-baseline justify-start gap-1 ">
                    <h3 className="text-lg font-semibold">{shopData.supplierName}</h3>
                    <div className="text-sm text-gray-500">
                        {shopData.productTypeName} - {shopData.wholesaleUnitsPerCase}ct
                    </div>
                </div>
                <Blend type={shopData.blend} />
            </div>
            <h3 className="w-full whitespace-pre-wrap text-wrap text-xl font-semibold">
                {productNameDisplay}
            </h3>
            <div className="flex h-full w-fit flex-row items-center justify-end gap-4 md:w-fit md:flex-row md:justify-between">
                {shopData.inventory && (
                    <AvailableInventoryBadge
                        inventory={shopData.inventory}
                        sellByCaseOnly={shopData.sellByCaseOnly}
                        wholesaleUnitsPerCase={shopData.wholesaleUnitsPerCase}
                    />
                )}
                {shopData.potency && (
                    <Potency
                        potency={shopData.potency}
                        isStatic={shopData.isStaticPotency ?? false}
                        className="text-sm text-gray-500"
                    />
                )}
            </div>
        </div>
    )
);
ProductTitle.displayName = "ProductInfo";

// Memoized product details section
const ProductDetails = memo(({ shopData }: { shopData: ShopBrowsingData }) => (
    <div className="flex h-full w-full flex-row-reverse items-center justify-between gap-2 text-left md:w-fit md:flex-row md:gap-4">
        {shopData.price && <ProductPriceDisplay price={shopData.price} variant="medium" />}
    </div>
));
ProductDetails.displayName = "ProductDetails";

const ProductRow = memo(({ shopData }: ProductRowProps) => {
    if (!shopData) return null;

    const productNameDisplay = shopData.name.startsWith(shopData.supplierName)
        ? shopData.name.replace(shopData.supplierName, "").trim()
        : shopData.name.trim();

    return (
        <div className="relative w-full border-b border-t bg-white p-2">
            <div className="flex h-full flex-col justify-between gap-2 md:flex-row md:gap-4">
                <Link
                    href={`/${shopData.state}/product/${shopData.productID}`}
                    className="col-span-3 block w-full"
                >
                    <div className="h-full w-full flex-row items-center justify-between gap-4 md:flex md:gap-2">
                        <ProductTitle productNameDisplay={productNameDisplay} shopData={shopData} />
                        <ProductDetails shopData={shopData} />
                    </div>
                </Link>

                {shopData.price && shopData.inventory && !shopData.inventory.NotAuthorized ? (
                    <div className="flex w-full md:mt-0 md:w-fit md:items-center">
                        <ProductRowCart
                            productID={shopData.productID}
                            wholesaleUnitsPerCase={shopData.wholesaleUnitsPerCase}
                            sellByCaseOnly={shopData.sellByCaseOnly}
                            retailerInventory={shopData.retailerInventory}
                            minOrderUnits={shopData.minOrderUnits}
                        />
                    </div>
                ) : shopData.inventory && shopData.inventory.NotAuthorized ? (
                    <div className="h-100 flex w-fit items-center justify-center text-center">
                        Not available to order
                    </div>
                ) : null}
            </div>
        </div>
    );
});

ProductRow.displayName = "ProductRow";

export default ProductRow;
