import { Button } from "@/components/shared/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/shared/ui/select";
import { ShopSort, ShopSortOption } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { ArrowDown01, ArrowDownAZ, ArrowUp10, ArrowUpZA } from "lucide-react";
import { useRouter } from "next/navigation";

export type SortKeyOption = {
    value: string;
    label: string;
    sortKey: string;
};

interface SortSelectorProps {
    sortOptions: ShopSortOption[];
    className?: string;
}

const SortSelector: React.FC<SortSelectorProps> = ({ sortOptions, className }) => {
    const sort = ShopStore.use.sort();
    const setSort = ShopStore.use.setSort();

    const router = useRouter();

    const currentValue =
        sortOptions.find((option) => option.sortKey === sort.sortKey)?.sortKey || "supplier";

    const handleSortKeyChange = (newValue: string) => {
        const selectedSort = sortOptions.find((option) => option.sortKey === newValue);
        if (selectedSort) {
            setSort(
                {
                    sortKey: selectedSort.sortKey as ShopSort["sortKey"],
                    type: selectedSort.type,
                    reverse: sort.reverse
                },
                router
            );
        }
    };

    const toggleSortDirection = () => {
        setSort(
            {
                sortKey: sort.sortKey,
                type: sort.type,
                reverse: !sort.reverse
            },
            router
        );
    };

    return (
        <div className="flex items-center gap-2">
            <Select value={currentValue} onValueChange={handleSortKeyChange}>
                <SelectTrigger className={`${className || ""}`}>
                    <SelectValue placeholder="Sort by..." />
                </SelectTrigger>
                <SelectContent>
                    {sortOptions.map((option) => (
                        <SelectItem key={option.sortKey} value={option.sortKey}>
                            {option.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
            <Button
                variant="outline"
                size="icon"
                onClick={toggleSortDirection}
                className="h-10 w-fit px-2 "
                aria-label={sort.reverse ? "Sort descending" : "Sort ascending"}
            >
                {sort.type === "string" ? (
                    sort.reverse ? (
                        <ArrowUpZA className="!h-6 !w-6" strokeWidth={1.5} />
                    ) : (
                        <ArrowDownAZ className="!h-6 !w-6" strokeWidth={1.5} />
                    )
                ) : sort.reverse ? (
                    <ArrowUp10 className="!h-6 !w-6" strokeWidth={1.5} />
                ) : (
                    <ArrowDown01 className="!h-6 !w-6" strokeWidth={1.5} />
                )}
            </Button>
        </div>
    );
};

export default SortSelector;
