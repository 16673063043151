"use client";

import { Accordion } from "@/components/shared/ui/accordion";
import { Button } from "@/components/shared/ui/button";
import {
    ShopCheckBoxFilter,
    ShopFilterSectionContent,
    ShopInputFilter,
    ShopRangeFilter
} from "@/lib/shop/shop-types";
import { FilterX } from "lucide-react";
import { memo } from "react";
import FilterOption from "./filter-option";
import FilterSection from "./filter-section";

interface FilterSidebarClientProps {
    filterSidebarContents: ShopFilterSectionContent[];
    clearFilters: () => void;
}

const FilterSidebarClient = memo<FilterSidebarClientProps>(
    ({ filterSidebarContents, clearFilters }) => (
        <div className="flex h-full flex-col pb-20 pt-5">
            <div className="flex items-baseline justify-start gap-4 border-b p-4 md:justify-between">
                <h1 className="text-3xl font-semibold md:text-2xl">Filters</h1>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={clearFilters}
                    className="p-4 text-xl md:text-sm"
                >
                    <FilterX /> Clear all
                </Button>
            </div>

            <div className="flex-1 overflow-y-auto p-4">
                <Accordion type="multiple" className="space-y-4" defaultValue={["Categories"]}>
                    {filterSidebarContents.map((filterSection) =>
                        filterSection.type === "accordion" ? (
                            <FilterSection
                                key={filterSection.title}
                                title={filterSection.title}
                                filterOptions={filterSection.options}
                                multiSelect={filterSection.multiSelect}
                            />
                        ) : null
                    )}
                </Accordion>

                <div className="mt-2 flex flex-col gap-1 pb-[200px]">
                    {filterSidebarContents.map((filterSection) =>
                        filterSection.type === "loose"
                            ? filterSection.options.map(
                                  (
                                      option: ShopCheckBoxFilter | ShopInputFilter | ShopRangeFilter
                                  ) => (
                                      <FilterOption
                                          item={option}
                                          key={`${option.filterID}-${option.value}`}
                                      />
                                  )
                              )
                            : null
                    )}
                </div>
            </div>
        </div>
    )
);

FilterSidebarClient.displayName = "FilterSidebarClient";

export default FilterSidebarClient;
