"use client";
import { ShopLayout } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { Grid2X2, List } from "lucide-react";
import { useRouter } from "next/navigation";

export default function LayoutSelection() {
    const layout = ShopStore.use.layout();
    const setLayout = ShopStore.use.setLayout();

    const router = useRouter();
    const handleSortChange = (layout: ShopLayout) => {
        setLayout(layout, router);
    };

    return (
        <div className="mb-4 flex items-center justify-end gap-2">
            <button
                onClick={() => handleSortChange("list")}
                className={`rounded-lg p-2 transition-colors ${
                    layout === "list"
                        ? "bg-gray-200 text-gray-800"
                        : "text-gray-500 hover:bg-gray-100"
                }`}
                aria-label="Switch to list view"
            >
                <List className="h-5 w-5" />
            </button>
            <button
                onClick={() => handleSortChange("grid")}
                className={`rounded-lg p-2 transition-colors ${
                    layout === "grid"
                        ? "bg-gray-200 text-gray-800"
                        : "text-gray-500 hover:bg-gray-100"
                }`}
                aria-label="Switch to small grid"
            >
                <Grid2X2 className="h-5 w-5" />
            </button>
        </div>
    );
}
