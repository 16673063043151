import {
    ShopCheckBoxFilter,
    ShopFilterOption,
    ShopInputFilter,
    ShopRangeFilter
} from "@/lib/shop/shop-types";
import CheckboxFilterOption from "./checkbox-filter";
import InputFilterOption from "./input-filter";
import RangeFilterOption from "./range-filter";

type FilterOptionProps = {
    item: ShopFilterOption;
    multiSelect?: boolean;
};

export default function FilterOption({ item, multiSelect }: FilterOptionProps) {
    if (item.type === "input") {
        return <InputFilterOption item={item as ShopInputFilter} multiSelect={multiSelect} />;
    }

    if (item.type === "range") {
        return <RangeFilterOption item={item as ShopRangeFilter} />;
    }

    return <CheckboxFilterOption item={item as ShopCheckBoxFilter} multiSelect={multiSelect} />;
}
