import Blend from "@/components/shared/blend";
import AvailableInventoryBadge from "@/components/shared/product/available-inventory-badge";
import Potency from "@/components/shared/product/potency";
import ProductPriceDisplay from "@/components/shared/product/product-price-display";
import { Badge } from "@/components/shared/ui/badge";
import { Card, CardContent, CardFooter, CardHeader } from "@/components/shared/ui/card";
import ImageWithFallback from "@/components/shared/ui/image-with-fallback";
import { ShopBrowsingData, ShopInventory, ShopPrice } from "@/lib/shop/shop-types";
import { Star, SwatchBook, Tag } from "lucide-react";
import Link from "next/link";
import { memo, useMemo } from "react";
import AddToCartFooter from "./product-card-footer";

const MemoizedProductImage = memo(function MemoizedProductImage({
    thumbnailImageURL,
    supplierLogoURL,
    name
}: Pick<ShopBrowsingData, "thumbnailImageURL" | "supplierLogoURL" | "name">) {
    return (
        <div className="relative aspect-square h-full w-full overflow-hidden rounded-t-[calc(1.2rem-8px)] border-8 border-white">
            <ImageWithFallback
                unoptimized
                className="border-white-15 h-full w-full object-contain"
                primarySrc={thumbnailImageURL}
                fallbackSrc={supplierLogoURL}
                alt={name}
                fill
            />
        </div>
    );
});

const SaleBadge = memo(function SaleBadge() {
    return (
        <Badge
            className="absolute -top-2 left-8 z-20 gap-1 bg-emerald-400 text-sm"
            variant={"defaultNoHover"}
        >
            <Tag className="h-5 w-5" />
            Sale
        </Badge>
    );
});

const SampleBadge = memo(function SampleBadge() {
    return (
        <Badge
            className="absolute -top-2 left-8 z-20 gap-1 bg-violet-600 text-sm"
            variant={"defaultNoHover"}
        >
            <SwatchBook className="h-5 w-5" />
            Sample
        </Badge>
    );
});

const NewBadge = memo(function NewBadge() {
    return (
        <Badge
            className="absolute -top-2 left-8 z-20 gap-1 bg-blue-500 text-sm"
            variant={"defaultNoHover"}
        >
            <Star className="h-5 w-5" />
            New
        </Badge>
    );
});

const MemoizedLoggedInContent = memo(function LoggedInContent({
    blend,
    potency,
    isStaticPotency,
    price,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    inventory
}: {
    blend: ShopBrowsingData["blend"];
    potency: string | null;
    isStaticPotency: boolean | null;
    price: ShopPrice;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    inventory: ShopInventory | null;
}) {
    return (
        <div className="flex w-full flex-col gap-2">
            <div className="flex w-full items-center justify-between gap-1">
                <Blend type={blend} />
                {potency ? (
                    <Potency
                        potency={potency}
                        isStatic={isStaticPotency ?? false}
                        className="text-right text-sm text-gray-500"
                    />
                ) : null}
            </div>

            <div className="flex w-full flex-row items-center justify-between gap-1">
                <ProductPriceDisplay price={price} className="items-start text-left" />
                {inventory ? (
                    <AvailableInventoryBadge
                        inventory={inventory}
                        sellByCaseOnly={sellByCaseOnly}
                        wholesaleUnitsPerCase={wholesaleUnitsPerCase}
                    />
                ) : null}
            </div>
        </div>
    );
});

const MemoizedPublicContent = memo(function PublicContent({
    blend
}: {
    blend: ShopBrowsingData["blend"];
}) {
    return (
        <>
            <div className="flex items-center justify-between gap-1">
                <Blend type={blend} />
            </div>
        </>
    );
});

const MemoizedCardContent = memo(function MemoizedCardContent({
    shopData,
    productNameDisplay
}: {
    shopData: ShopBrowsingData;
    productNameDisplay: string;
}) {
    return (
        <CardContent className="flex h-full flex-col p-3">
            <div className="mb-4">
                <h3 className="text-lg">{shopData.supplierName}</h3>
                <h3 className="text-lg font-semibold">{productNameDisplay}</h3>
                <div className="flex flex-row justify-between text-sm ">
                    <span className="text-gray-500">{shopData.productTypeName}</span>{" "}
                    {shopData.wholesaleUnitsPerCase}ct
                </div>
            </div>

            <div className="mt-auto space-y-2">
                {shopData.price ? (
                    <MemoizedLoggedInContent
                        blend={shopData.blend}
                        potency={shopData.potency}
                        inventory={shopData.inventory}
                        isStaticPotency={shopData.isStaticPotency}
                        sellByCaseOnly={shopData.sellByCaseOnly}
                        price={shopData.price}
                        wholesaleUnitsPerCase={shopData.wholesaleUnitsPerCase}
                    />
                ) : (
                    <MemoizedPublicContent blend={shopData.blend} />
                )}
            </div>
        </CardContent>
    );
});

export default function ProductCard({ shopData }: { shopData: ShopBrowsingData }) {
    const { borderColor, productNameDisplay } = useMemo(() => {
        let borderColor = "";
        if (shopData.price && shopData.price.isSale) {
            borderColor = "bg-gradient-to-b from-emerald-400 to-cyan-400 p-2";
        } else if (shopData.isNew && !shopData.isSample) {
            borderColor = "bg-gradient-to-b from-blue-500 to-green-500 p-2";
        }

        let productNameDisplay = shopData.name;
        if (productNameDisplay.includes(shopData.supplierName)) {
            productNameDisplay = productNameDisplay.replace(shopData.supplierName, "");
        }

        return { borderColor, productNameDisplay };
    }, [shopData]);

    if (!shopData) return null;

    return (
        <div className={`relative rounded-[calc(1rem-5px)] ${borderColor} h-full`}>
            {shopData.price?.isSale && !shopData.isSample ? <SaleBadge /> : null}
            {shopData.isSample ? <SampleBadge /> : null}
            {shopData.isNew && !shopData.isSample ? <NewBadge /> : null}

            <Card
                className={`relative flex h-full flex-col overflow-hidden ${borderColor === "" ? "rounded-[calc(1rem-5px)]" : "rounded-[calc(1rem-10px)]"} shadow-lg`}
            >
                <Link
                    className="relative flex flex-grow flex-col "
                    href={`/${shopData.state}/product/${shopData.productID}`}
                >
                    <CardHeader className="p-0">
                        <MemoizedProductImage
                            thumbnailImageURL={shopData.thumbnailImageURL}
                            supplierLogoURL={shopData.supplierLogoURL}
                            name={shopData.name}
                        />
                    </CardHeader>

                    <MemoizedCardContent
                        shopData={shopData}
                        productNameDisplay={productNameDisplay}
                    />
                </Link>

                {shopData.price && shopData.inventory && !shopData.inventory.NotAuthorized ? (
                    <CardFooter className="p-0">
                        <AddToCartFooter
                            productID={shopData.productID}
                            wholesaleUnitsPerCase={shopData.wholesaleUnitsPerCase}
                            sellByCaseOnly={shopData.sellByCaseOnly}
                            retailerInventory={shopData.retailerInventory}
                            minOrderUnits={shopData.minOrderUnits}
                        />
                    </CardFooter>
                ) : shopData.inventory && shopData.inventory.NotAuthorized ? (
                    <CardFooter className="p-1">
                        <div className="w-full text-center">Not available to order</div>
                    </CardFooter>
                ) : null}
            </Card>
        </div>
    );
}
