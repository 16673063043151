import Blend from "@/components/shared/blend";
import { Checkbox } from "@/components/shared/ui/checkbox";
import { Label } from "@/components/shared/ui/label";
import { SHOW_BELOW_ONLY_FILTER_ID, TARGET_DOI_FILTER_ID } from "@/lib/shop/shop-constants";
import { ShopCheckBoxFilter, ShopProductPageData } from "@/lib/shop/shop-types";
import { ShopStore } from "@/state-management/stores/shop-store";
import { useRouter } from "next/navigation";

type CheckboxFilterOptionProps = {
    item: ShopCheckBoxFilter;
    multiSelect?: boolean;
};

export default function CheckboxFilterOption({ item, multiSelect }: CheckboxFilterOptionProps) {
    const getFilter = ShopStore.use.getFilter();
    const filters = ShopStore.use.filters();
    const setFilter = ShopStore.use.setFilter();
    const removeFilter = ShopStore.use.removeFilter();

    const router = useRouter();

    const handleFilterChange = (
        filterID: string,
        value: string,
        multiSelect?: boolean,
        checked?: boolean
    ) => {
        if (multiSelect) {
            const currentValues = (getFilter(filterID) || []) as Array<string>;
            if (checked) {
                currentValues.push(String(value));
            } else {
                const index = currentValues.indexOf(String(value));
                if (index > -1) {
                    currentValues.splice(index, 1);
                }
            }
            if (currentValues.length === 0) {
                removeFilter(filterID, router);
            } else {
                setFilter(filterID, currentValues, router);
            }
        } else {
            if (checked !== undefined) {
                if (checked) {
                    setFilter(filterID, checked.toString(), router);
                } else {
                    removeFilter(filterID, router);
                }
            } else {
                setFilter(filterID, value, router);
            }
        }
    };

    const itemKey = `${item.filterID}-${item.value}`;
    let isChecked = false;

    if (multiSelect) {
        const values = (filters[item.filterID] || []) as string[];
        isChecked = values.includes(String(item.value));
    } else {
        isChecked = Boolean(filters[item.filterID] === "true");
    }

    let active = true;

    if (item.filterID === SHOW_BELOW_ONLY_FILTER_ID) {
        const targetDOI = getFilter(TARGET_DOI_FILTER_ID) as string;
        if (!targetDOI) {
            active = false;
        } else {
            active = Number(targetDOI) > 0;
        }
    }

    return (
        <div
            key={itemKey}
            className={`flex items-center space-x-2 ${item.filterID === SHOW_BELOW_ONLY_FILTER_ID ? "pl-4" : ""}`}
        >
            <Checkbox
                id={itemKey}
                checked={isChecked}
                onCheckedChange={(checked) =>
                    handleFilterChange(item.filterID, item.value, multiSelect, checked as boolean)
                }
                className="h-8 w-8 md:h-4 md:w-4"
                disabled={!active}
            />
            <Label
                htmlFor={itemKey}
                className="flex w-full cursor-pointer flex-row items-center justify-between gap-2 text-xl md:text-sm"
            >
                {item.filterID === "blends" ? (
                    <Blend type={item.value as ShopProductPageData["blend"]} />
                ) : (
                    item.displayName
                )}
                {item.imageURL ? (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                        key={`image-${itemKey}`}
                        src={item.imageURL}
                        alt={`${item.displayName} image`}
                        className="h-6 w-auto"
                    />
                ) : null}
            </Label>
        </div>
    );
}
